<template>
  <div class="leasingOverview">
    <template v-if="hasPermission">
      <div
        class="common-shadow-panel"
        v-if="decisionId"
      >
        <h2 class="w-title-gradients">数据总览</h2>
        <ul
          class="common-data-list"
          :class="{'pointer':overviewInfo.length>1}"
          v-if="overviewInfo && overviewInfo.length"
          v-show="showOverviewPanel"
          @click="overviewInfo.length>1 && (showOverviewPanel=!showOverviewPanel)"
        >
          <li>
            <h3>
              {{overviewInfo[0].stepName || '-'}}租金目标
              <el-tooltip
                class="item"
                effect="dark"
                :content="`根据${overviewInfo[0].stepName}${overviewInfo[0].stepDate}租金计划所得的标准租金目标`"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </h3>
            <p class="number-p">
              <span class="number-span">{{overviewInfo[0].rent || '-'}}</span>
              元
            </p>
            <p style="margin-bottom:4px">场均租金 <span class="data">{{(overviewInfo[0].avgRent || '-') + unit}}</span></p>
            <p>免租期扣除 <span class="data">{{overviewInfo[0].afterFree || '-'}}元</span></p>
          </li>
          <li>
            <h3>
              {{overviewInfo[0].stepName || '-'}}{{user.topuid === 6467 ? '物业管理费目标' : '综合管理费目标'}}
              <el-tooltip
                class="item"
                effect="dark"
                :content="`根据${overviewInfo[0].stepName}${overviewInfo[0].stepDate}租金计划所得的标准管理费目标`"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </h3>
            <p class="number-p">
              <span class="number-span">{{overviewInfo[0].cost || '-'}}</span>
              元
            </p>
            <p>场均管理费 <span>{{(overviewInfo[0].avgCost || '-') + unit}}</span></p>
          </li>
          <li>
            <h3>
              {{overviewInfo[0].stepName || '-'}}收入目标
              <el-tooltip
                class="item"
                effect="dark"
                :content="`根据${overviewInfo[0].stepName}${overviewInfo[0].stepDate}租金计划所得的总收入目标`"
                placement="right"
              >
                <span>
                  <i class="icon-help-tips"></i>
                </span>
              </el-tooltip>
            </h3>
            <p class="number-p">
              <span class="number-span">{{overviewInfo[0].income || '-'}}</span>
              元
            </p>
            <p>场均收入 <span class="data">{{(overviewInfo[0].avgIncome || '-') + unit}}</span></p>
          </li>
        </ul>
        <ul
          class="common-data-list w-singed-table pointer"
          style="margin-right:24px"
          ref="moreInfoPanel"
          v-if="overviewInfo && overviewInfo.length"
          @click="showOverviewPanel = !showOverviewPanel"
          v-show="!showOverviewPanel"
        >
          <li>
            <p
              v-for="(item,index) in overviewInfo"
              :key="item.decisionStepName"
              class="list"
            >
              <span class="name ellipsis">
                {{`${item.stepName || '-'}租金目标`}}<br>
                {{`${item.stepDate || '-'}`}}
              </span>
              <span class="data">
                {{item.rent || '-'}}元
              </span>
              <span
                class="trend"
                v-if="index!==0"
                :class="{
                  'trend-up': item.rentUp>0,
                  'trend-down': item.rentUp<0
                }"
              >
                <i :class="{
                    'c-icon-up': item.rentUp>0,
                    'c-icon-down': item.rentUp<0
                  }"></i>
                {{item.rentUp || '-'}}%
              </span>
            </p>
          </li>
          <li>
            <p
              v-for="(item,index) in overviewInfo"
              :key="item.decisionStepName"
              class="list"
            >
              <span
                :title="`${item.stepName || '-'}${user.topuid === 6467 ? '物业管理费目标' : '综合管理费目标'} ${item.stepDate || '-'}`"
                class="name ellipsis"
              >
                {{`${item.stepName || '-'}`}}{{user.topuid === 6467 ? '物业管理费目标' : '综合管理费目标'}}<br>
                {{`${item.stepDate || '-'}`}}
              </span>
              <span class="data">{{item.cost || '-'}}元</span>
              <span
                class="trend"
                v-if="index!==0"
                :class="{
                  'trend-up': item.costUp>0,
                  'trend-down': item.costUp<0
                }"
              >
                <i :class="{
                    'c-icon-up': item.costUp>0,
                    'c-icon-down': item.costUp<0
                  }"></i>
                {{item.costUp || '-'}}%
              </span>
            </p>
          </li>
          <li>
            <p
              v-for="(item,index) in overviewInfo"
              :key="item.decisionStepName"
              class="list"
            >
              <span
                :title="`${item.stepName || '-'}收入目标 ${item.stepDate || '-'}`"
                class="name ellipsis"
              >
                {{`${item.stepName || '-'}收入目标`}}<br>
                {{`${item.stepDate || '-'}`}}
              </span>
              <span class="data">{{item.income || '-'}}元</span>
              <span
                class="trend"
                v-if="index!==0"
                :class="{
                  'trend-up': item.incomeUp>0,
                  'trend-down': item.incomeUp<0
                }"
              >
                <i :class="{
                    'c-icon-up': item.incomeUp>0,
                    'c-icon-down': item.incomeUp<0
                  }"></i>
                {{item.incomeUp || '-'}}%
              </span>
            </p>
          </li>
        </ul>
        <p
          class="down-panel"
          v-if="overviewInfo && overviewInfo.length>3"
          v-show="!showOverviewPanel"
          @mouseover="showMoreInfo"
          @mouseout="stopInterval"
        >
          <i class="icon-show-more"></i>
        </p>
        <div
          class="no-recommend-brand"
          style="height:279px"
          v-if="overviewInfo && overviewInfo.length===0"
        >
          <img
            style="margin-top: 40px;"
            src="@/assets/images/public/not-plandata.png"
            alt=""
          >
          <p>暂无数据</p>
        </div>
      </div>
      <div
        class="common-shadow-panel"
        v-if="decisionId"
      >
        <h2 class="w-title-gradients">决策概览</h2>
        <h3 class="w-title-border-left">品牌策略</h3>
        <div class="box-1">
          <div
            class="fl side-box"
            v-if="hasDataBrand"
          >
            <div
              ref="brandDecision"
              class="pie-box brand-decision"
            ></div>
          </div>
          <div
            class="main-box"
            v-if="hasDataBrand"
          >
            <ul class="common-block-list">
              <!-- <li
                v-for="item in brandCardList"
                :key="item.brandLevelType"
              >
                <h4>{{['标杆', '标准', '非标'][item.brandLevelType-1]}}品牌目标占比</h4>
                <p class="num">{{item.brandLevelValue}}%</p>
                <ul>
                  <li
                    v-for="(ele,i) in item.brandGradeType"
                    :key="i"
                  >
                    <span>{{item.brandGradeValue[i] | notEmpty}}%</span>
                    {{['A', 'B', 'C', 'D'][i]}}级
                  </li>
                </ul>
              </li> -->
              <!-- 新结构 -->
              <li
                v-for="(item, index) in brandCardList"
                :key="index+item.brandLevelType"
              >
                <h4>{{item.brandLevelType}}级品牌<span style="white-space: nowrap">目标占比</span></h4>
                <p class="num">
                  {{item.brandLevelValue}}<span>%</span>
                </p>
                <i class="iconfont">{{['&#xe6c5;','&#xe6c1;','&#xe6c4;','&#xe6c2;','&#xe6c3;'][index]}}</i>
              </li>
            </ul>
          </div>
          <div
            class="no-recommend-brand"
            style="height:279px"
            v-else
          >
            <img
              style="margin-top: 40px;"
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>当前租赁决策文件未配置品牌策略</p>
            <div class="button-check-ew mt10">
              <span
                class="button-basic"
                @click="showDrawerStrategy(0, 0)"
              >去添加</span>
            </div>
          </div>
        </div>
        <div class="box-1">
          <h3 class="w-title-border-left">业态策略</h3>
          <div
            class="fl side-box"
            v-if="hasDataFormat"
          >
            <div
              ref="formatDecision"
              class="pie-box store-decision"
            ></div>
          </div>
          <div
            class="main-box is-scroll-y"
            style="height:330px;overflow-y:scroll"
            v-if="hasDataFormat"
          >
            <el-table
              class="tbs-black"
              :data="tbsFormat"
            >
              <el-table-column
                prop="industry"
                label="业态名称"
                width="160"
              >
              </el-table-column>
              <el-table-column
                prop="planArea"
                label="规划面积(㎡)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="areaRate"
                label="面积占比(%)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="standardRent"
                :label="`策略租金${unit&&'('+unit+')'}`"
                width="160"
              >
              </el-table-column>
              <el-table-column
                prop="rentYear"
                label="租赁年限(年)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="rentFreeTime"
                label="免租时长(天)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="rentIncreasingRate"
                label="租金增长率(%)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="managementCost"
                :label="`${user.topuid === 6467 ? '物业' : '综合'}管理费${unit&&'('+unit+')'}`"
                width="160"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            class="no-recommend-brand"
            style="height:279px"
            v-else
          >
            <img
              style="margin-top: 40px;"
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>当前租赁决策文件未配置业态策略</p>
            <div class="button-check-ew mt10">
              <span
                class="button-basic"
                @click="showDrawerStrategy(1, 0)"
              >去添加</span>
            </div>
          </div>
        </div>
        <div class="box-1">
          <h3 class="w-title-border-left">主次力店策略</h3>
          <div
            class="fl side-box"
            v-if="hasDataStore"
          >
            <div
              ref="storeDecision"
              class="pie-box store-decision"
            ></div>
          </div>
          <div
            class="main-box is-scroll-y"
            style="height:300px;overflow-y:scroll"
            v-if="hasDataStore"
          >
            <el-table
              class="tbs-black"
              :data="tbsStore"
            >
              <el-table-column
                prop="mainBerthType"
                label="主次力店"
                width="160"
              >
              </el-table-column>
              <el-table-column
                prop="planArea"
                label="规划面积(㎡)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="areaRate"
                label="面积占比(%)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="standardRent"
                :label="`策略租金${unit&&'('+unit+')'}`"
                width="160"
              >
              </el-table-column>
              <el-table-column
                prop="rentYear"
                label="租赁年限(年)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="rentFreeTime"
                label="免租时长(天)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="rentIncreasingRate"
                label="租金增长率(%)"
                width="140"
              >
              </el-table-column>
              <el-table-column
                prop="managementCost"
                :label="`${user.topuid === 6467 ? '物业' : '综合'}管理费${unit&&'('+unit+')'}`"
                width="160"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            class="no-recommend-brand"
            style="height:279px"
            v-else
          >
            <img
              style="margin-top: 40px;"
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>当前租赁决策文件未配置主次力店策略</p>
            <div class="button-check-ew mt10">
              <span
                class="button-basic"
                @click="showDrawerStrategy(2, 0)"
              >去添加</span>
            </div>
          </div>
        </div>
        <div class="box-1">
          <h3 class="w-title-border-left fl">租金计划</h3>
          <div class="date-picker-sty fr">
            <el-select
              ref="projectSelector"
              class="input-solid"
              popper-class="w-block-select-down yellow-select-panel"
              @change="handleRentPlan"
              v-model="selectLeasing"
            >
              <el-option
                v-for="item in selectOption"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              >{{item.label}}</el-option>
            </el-select>
          </div>
          <div class="clearfix"></div>
          <div
            class="fl side-box mt-40"
            style="width:39%;height:420px;margin-right:60px"
            v-if="hasDataRentPlan"
          >
            <div
              ref="leasingPlan"
              class="pie-box store-decision"
              style="height:420px"
            ></div>
          </div>
          <div
            class="main-box is-scroll-y"
            style="overflow-y:scroll"
            v-if="hasDataRentPlan"
          >
            <el-table
              class="tbs-black w-singed-table rent-plan-tbs"
              :data="tbsRentPlan"
            >
              <el-table-column
                prop="name"
                :label="['楼宇楼层', '业态', '主次力店'][selectLeasing-1]"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="berthNums"
                label="铺位数量"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="industryNums"
                label="业态数量"
                width="100"
              >
              </el-table-column>
              <el-table-column
                prop="buildingArea"
                label="建筑面积(㎡)"
                width="130"
              >
              </el-table-column>
              <el-table-column
                prop="areaRate"
                label="面积占比(%)"
                width="130"
              >
              </el-table-column>
              <el-table-column
                prop="avgRent"
                :label="`首阶段场均租金 (元)`"
                width="160"
              >
              </el-table-column>
              <el-table-column
                prop="sumRent"
                label="首阶段租金规模 (元)"
                width="160"
              >
              </el-table-column>
            </el-table>
          </div>
          <div
            class="no-recommend-brand"
            style="height:279px"
            v-else
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>暂无数据</p>
          </div>
        </div>
        <div class="box-1">
          <h3 class="w-title-border-left fl">落位规划</h3>
          <div class="date-picker-sty fr">
            <el-select
              ref="projectSelector"
              class="input-solid"
              popper-class="w-block-select-down yellow-select-panel"
              @change="getDataBerthPlanListAndLoop"
              v-model="selectLanding"
            >
              <el-option
                v-for="item in selectOption"
                :label="item.label"
                :value="item.value"
                :key="item.value"
              >{{item.label}}</el-option>
            </el-select>
          </div>
          <div class="clearfix"></div>
          <div
            class="fl side-box"
            v-if="hasDatalandingPlan"
          >
            <div
              ref="landingPlaned"
              class="pie-box store-decision"
            ></div>
          </div>
          <div
            class="main-box"
            v-if="hasDatalandingPlan"
          >
            <ul class="common-block-list">
              <!-- <li
                v-for="item in landingCardList"
                :key="item.brandLevelType"
              >
                <h4>{{['标杆品牌目标占比', '标准品牌目标占比', '非标品牌目标占比'][item.brandLevelType-1]}}</h4>
                <p class="num">{{item.brandLevelValue}}%</p>
                <ul>
                  <li
                    v-for="(ele,i) in item.brandGradeType"
                    :key="i"
                  >
                    <span>{{item.brandGradeValue[i]}}%</span>
                    {{['A', 'B', 'C', 'D'][i]}}级
                  </li>
                </ul>
              </li> -->
              <li
                v-for="(item, index) in landPlan"
                :key="index+item.name"
              >
                <h4>{{item.name}}级品牌<span style="white-space: nowrap">目标占比</span></h4>
                <p class="num">
                  {{item.num}}<span>%</span>
                </p>
                <i class="iconfont">{{['&#xe6c5;','&#xe6c1;','&#xe6c4;','&#xe6c2;','&#xe6c3;'][index]}}</i>
              </li>
            </ul>
            <div
              style="margin-top:32px;overflow-y:scroll"
              class="is-scroll-y"
            >
              <el-table
                class="tbs-black landing-tbs w-singed-table"
                :data="tbsLanding"
              >
                <el-table-column
                  type="index"
                  label="序号"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="name"
                  :label="['楼宇楼层', '业态', '主次力店'][selectLanding-1]"
                  width="180"
                >
                </el-table-column>
                <el-table-column
                  prop="brandNums"
                  label="落位品牌数量"
                >
                </el-table-column>
                <el-table-column
                  prop="berthNums"
                  label="已落位铺位"
                >
                </el-table-column>
                <el-table-column
                  prop="area"
                  label="已落位面积(㎡)"
                >
                </el-table-column>
                <el-table-column
                  prop="areaProgress"
                  label="落位进度(%)"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div
            class="no-recommend-brand"
            style="height:279px"
            v-else
          >
            <img
              src="@/assets/images/public/not-plandata.png"
              alt=""
            >
            <p>暂无数据</p>
          </div>
        </div>
      </div>
      <div
        class="no-recommend-brand"
        style="height:279px"
        v-else
      >
        <img
          style="margin-top: 227px;"
          src="@/assets/images/public/not-plandata.png"
          alt=""
        >
        <p>当前项目不存在租赁决策文件</p>
        <div class="button-check-ew mt10">
          <span
            class="button-basic"
            @click="showDrawer"
          >去添加</span>
        </div>
      </div>
    </template>
    <div
      class="simpleInfoPanel"
      v-else
    >
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import echarts from 'echarts'
import { powerAuth, toThousands } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'leasingOverview',
  data () {
    return {
      unit: '',
      intervalNum: null,
      showOverviewPanel: true,
      hasPermission: true,
      overviewInfo: {},
      tbsFormat: [],
      tbsStore: [],
      brandCardList: [],
      landingCardList: [],
      hasDataBrand: true,
      hasDataStore: true,
      hasDataFormat: true,
      hasDataRentPlan: true,
      hasDatalandingPlan: true,
      tbsLanding: [],
      tbsRentPlan: [],
      selectLeasing: 1,
      selectLanding: 1,
      selectOption: [
        {
          label: '按楼层',
          value: 1
        },
        {
          label: '按规划业态',
          value: 2
        },
        {
          label: '按主次力店',
          value: 3
        }
      ],
      landPlan: []
    }
  },
  filters: {
    notEmpty: (val) => {
      if (!val && val !== 0) {
        return '-'
      } else {
        return val
      }
    }
  },
  props: {
    projectId: {
      type: Number
    },
    decisionId: {
      type: Number
    }
  },
  watch: {
    projectId (newValue, oldValue) {
      console.log('projectId', this.projectId)
    },
    decisionId (newValue, oldValue) {
      console.log('decisionId', this.decisionId)
      this.hasDataDo()
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  mounted () {
    if (powerAuth(210, 'show')) {
      this.hasDataDo()
    } else {
      this.hasPermission = false
    }
  },
  methods: {
    stopInterval () {
      clearInterval(this.intervalNum)
      this.intervalNum = null
    },
    showMoreInfo () {
      const e = this.$refs.moreInfoPanel
      this.intervalNum = setInterval(() => {
        e.scrollTop += 1
      }, 10)
    },
    hasnotPermission () {
      this.$message.error({
        message: '暂无权限，如需开通权限，请联系管理员开通'
      })
    },
    showDrawerStrategy (index, type) {
      this.$emit('showDrawer', { index, type })
    },
    hasDataDo () {
      if (this.decisionId) {
        this.getDataBerthPlanCard()
        this.getDataBerthPlanListAndLoop()
        this.getDataDecisionsOverview()
        this.handleRentPlan()
        this.getDataLeasingDecisionsStep()
        this.getDataStore()
        this.getDataFormat()
        this.getDataBrand()
      }
    },
    getDataBrand () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.getBrandDecisionsPie, { params: params }).then(res => {
        if (res.data.code === 0) {
          res.data.data.labels = res.data.data.labels.map(item => {
            return item + '级'
          })
          const resData = res.data.data
          if (resData.values) {
            this.hasDataBrand = true
            this.$nextTick(() => {
              this.createPie('brandDecision', 1, resData)
            })
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.axios.get(api.handleBrandDecisions, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData.brandLevels && resData.brandLevels.length) {
            this.hasDataBrand = true
            this.brandCardList = resData.brandLevels
          } else {
            this.hasDataBrand = false
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataFormat () {
      const params = {
        decisionId: this.decisionId
      }
      const hasData = [false, false]
      this.axios.get(api.getFormatDecisionsList, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData0 = res.data.data
          hasData[0] = Boolean(resData0 && resData0.length)
          this.tbsFormat = resData0
        }
        this.axios.get(api.getFormatDecisionsPie, { params: params }).then(res => {
          if (res.data.code === 0) {
            const resData1 = res.data.data
            if (resData1 && resData1.labels && resData1.labels.length) {
              hasData[1] = true
            } else {
              hasData[1] = false
            }
            console.log('xxx', hasData)
            if (hasData[0] === false || hasData[1] === false) {
              this.hasDataFormat = false
            } else {
              this.hasDataFormat = true
              this.$nextTick(() => {
                this.createPie('formatDecision', 1, resData1)
              })
            }
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataStore () {
      const params = {
        decisionId: this.decisionId
      }
      const hasData = [false, false]
      this.axios.get(api.getStoreList, { params: params }).then(res => { // 列表
        if (res.data.code === 0) {
          const resData0 = res.data.data
          this.tbsStore = resData0
          resData0 && resData0.length === 0 && (hasData[0] = false)
        }
        this.axios.get(api.getStorePie, { params: params }).then(res => { // berthLoop', // 获取主次力店策略环图 GET
          if (res.data.code === 0) {
            const resData1 = res.data.data
            hasData[1] = Boolean(resData1.labels && resData1.labels.length)
            if (hasData[0] === false && hasData[1] === false) {
              this.hasDataStore = false
            } else {
              this.hasDataStore = true
              this.$nextTick(() => {
                this.createPie('storeDecision', 1, resData1)
              })
            }
          }
        }, rej => {
          console.log(rej)
        }).catch(err => {
          console.log(err)
        })
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataLeasingDecisionsStep () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.decisionsStep, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleRentPlan () {
      const params = {
        decisionId: this.decisionId,
        type: this.selectLeasing
      }
      const hasData = [false, false]
      this.axios.get(api.decisionsRentList, { params: params }).then(res => { // /rentPlan/list 列表
        if (res.data.code === 0) {
          const resData = res.data.data
          this.tbsRentPlan = resData.map(item => {
            item.sumRent = toThousands(item.sumRent)
            return item
          })
          if (this.tbsRentPlan.length) {
            hasData[0] = true
          } else {
            hasData[0] = false
          }
          this.axios.get(api.decisionsHistogram, { params: params }).then(res => { // histogram 获取租金计划分布柱状图
            if (res.data.code === 0) {
              const resData = res.data.data
              if (resData.labels && resData.labels.length) {
                hasData[1] = true
              } else {
                hasData[1] = false
              }
              if (hasData[0] === false && hasData[1] === false) {
                this.hasDataRentPlan = false
              } else {
                this.hasDataRentPlan = true
                this.$nextTick(() => {
                  setTimeout(() => {
                    this.createBar('leasingPlan', resData)
                  }, 100)
                })
              }
            }
          }, rej => {
            console.log(rej)
          }).catch(err => {
            console.log(err)
          })
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataDecisionsOverview () {
      const params = {
        decisionId: this.decisionId
      }
      this.axios.get(api.decisionsOverview, { params: params }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData && resData.length) {
            this.overviewInfo = resData.map(item => {
              item.rent = toThousands(item.rent)
              item.cost = toThousands(item.cost)
              item.income = toThousands(item.income)
              item.avgRent = toThousands(item.avgRent)
              item.avgCost = toThousands(item.avgCost)
              item.avgIncome = toThousands(item.avgIncome)
              item.afterFree = toThousands(item.afterFree)
              return item
            })
            this.unit = ['', '元/日/㎡', '元/月/㎡'][resData[0].rentUnit]
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataBerthPlanListAndLoop () {
      const params = {
        decisionId: this.decisionId,
        type: this.selectLanding
      }
      const hasData = [false, false]
      this.axios.get(api.berthPlanListAndLoop, { params: params }).then(res => { // berthPlan/listAndLoop获取落位计划列表和环图
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData) {
            if (resData.brandLevels && resData.brandLevels.length) {
              this.landPlan = resData.brandLevels
            }
            if (resData.list && resData.list.length) {
              this.tbsLanding = resData.list.map(item => {
                item.area += '㎡'
                item.areaProgress += '%'
                return item
              })
              hasData[0] = true
            } else {
              hasData[0] = false
            }
            if (resData.loops.labels && resData.loops.labels.length) {
              this.$nextTick(() => {
                setTimeout(() => {
                  this.createPie('landingPlaned', 1, resData.loops)
                }, 100)
              })
              hasData[1] = true
            } else {
              hasData[1] = false
            }
          }
          if (hasData[0] === false && hasData[1] === false) {
            this.hasDatalandingPlan = false
          } else {
            this.hasDatalandingPlan = true
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataBerthPlanCard () {
      this.axios.get(api.getBerthPlanCard, { params: { decisionId: this.decisionId } }).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.landingCardList = resData
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    showDrawer () {
      this.$emit('handleDrawer', 0)
    },
    handleSubmit (data) {
      console.log('data', data)
    },
    createBar (box, resData) {
      const option = {
        grid: {
          top: 40,
          right: 70,
          left: 70,
          bottom: 60
        },
        tooltip: {
          show: true,
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          formatter: (params) => {
            let str = '<div style="padding:6px;line-height:24px">'
            str += '<span class="cls-common">' + params[0].axisValue + '</span>'
            str += '</br>'
            const unitList = ['㎡', '万元']
            for (let i = 0; i < params.length; i++) {
              const item = params[i]
              str += item.marker
              str += '<span style="color: #808191">' + item.seriesName + '：</span>'
              str += item.data + ' '
              str += unitList[i] + '<br>'
            }
            str += '</div>'
            return str
          }
        },
        legend: {
          show: true,
          bottom: 10,
          itemWidth: 14,
          itemHeight: 8,
          itemGap: 32,
          textStyle: {
            color: '#808191'
          }
        },
        xAxis: {
          nameTextStyle: {
            color: 'rgba(255,255,255,.45)'
          },
          type: 'category',
          axisLabel: {
            color: 'rgba(255,255,255,.45)'
          },
          splitLine: { // 在grid的分割线
            show: false
          },
          axisTick: { // 坐标刻度
            show: false
          }
        },
        color: ['#00C567', '#00D7D5', '#1BD2FB', '#661CF4', '#B74EDB', '#FB6C14', '#FFA134', '#15FFA4'],
        yAxis: [
          {
            splitLine: { // 在grid的分割线
              show: false
            },
            name: '建筑面积/㎡',
            type: 'value',
            nameTextStyle: {
              color: '#808191'
            },
            axisLabel: {
              color: 'rgba(255,255,255,.45)'
            },
            axisTick: {
              show: false
            }
          },
          {
            splitLine: { // 在grid的分割线
              show: false
            },
            nameTextStyle: {
              color: '#808191'
            },
            name: '租金规模/万元',
            type: 'value',
            axisLabel: {
              color: 'rgba(255,255,255,.45)'
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            type: 'bar',
            barGap: '30%',
            label: {
              show: false,
              position: [5, 2],
              color: '#fff'
            },
            barWidth: 24
          },
          {
            type: 'bar',
            barGap: '30%',
            yAxisIndex: 1,
            label: {
              show: false,
              position: [5, 2],
              color: '#fff'
            },
            barWidth: 24
          }
        ]
      }
      let chart = null
      if (chart) {
        chart.dispose()
      }
      chart = echarts.init(this.$refs[box])
      chart.setOption(option)
      chart.setOption({
        xAxis: {
          data: resData.labels
        },
        series: resData.series
      })
      if (document.documentElement.clientWidth < 1440) {
        chart.setOption({
          series: [
            {
              barWidth: 10
            },
            {
              barWidth: 10
            }
          ]
        })
      }
      if (document.documentElement.clientWidth > 1440) {
        chart.setOption({
          series: [
            {
              barWidth: 24
            },
            {
              barWidth: 24
            }
          ]
        })
      }
      let flag = true
      window.addEventListener('resize', () => {
        chart.resize()
        if (document.documentElement.clientWidth < 1440 && flag) {
          chart.setOption({
            series: [
              {
                barWidth: 10
              },
              {
                barWidth: 10
              }
            ]
          })
          flag = false
        }
        if (document.documentElement.clientWidth > 1440 && !flag) {
          chart.setOption({
            series: [
              {
                barWidth: 24
              },
              {
                barWidth: 24
              }
            ]
          })
          flag = true
        }
      })
    },
    createPie (box, hasLegend, resData) {
      console.log('resData', resData)
      // box 容器ref值
      // hasLegend 是否设置legend
      // resData数据
      let emphasisFormat = []
      if (box === 'brandDecision') {
        emphasisFormat = [
          '{a|{c}%}',
          '{b|{b}品牌}'
        ]
      } else if (box === 'landingPlaned' || box === 'storeDecision') {
        emphasisFormat = [
          '{a|{d}%}',
          '{b|{b}: {c}㎡}'
        ]
      } else if (box === 'formatDecision') {
        emphasisFormat = [
          '{a|{d}%}',
          '{b|{b}}',
          '{b|{c}㎡}'
        ]
      } else {
        emphasisFormat = [
          '{a|{d}%}',
          '{b|{b}: {c}万元}'
        ]
      }
      const colorList = [
        '#83AF9B',
        '#37ABFD',
        '#10DD71',
        '#F968B0',
        '#166EE9',
        '#DE0A0A',
        '#5C61B6',
        '#DED00A',
        '#DE900A',
        '#B668F9',
        '#16BEE9',
        '#FF8800'
      ]
      if (box === 'formatDecision') {
        const len = resData ? resData.labels.length : 0
        colorList.splice(len - 1, 0, '#808080')
      }
      const seriesData = []
      for (let i = 0; i < resData.labels.length; i++) {
        seriesData.push(
          {
            value: resData.values[i],
            name: resData.labels[i]
          }
        )
      }
      let maxItem = seriesData[0]
      for (let i = 0; i < seriesData.length; i++) {
        if (Number(maxItem.value) < Number(seriesData[i].value)) {
          maxItem = seriesData[i]
        }
      }
      let chart = null
      if (!this.$refs[box]) {
        return
      }
      chart = echarts.init(this.$refs[box])
      chart.setOption({
        tooltip: {
          show: false,
          trigger: 'item'
        },
        legend: {
          show: hasLegend,
          top: 'bottom',
          icon: 'circle',
          itemHeight: 8,
          itemWidth: 8,
          textStyle: {
            color: '#808191'
          },
          left: 'center'
        },
        color: colorList,
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['70', '85'],
            center: ['50%', '42%'],
            hoverOffset: 5,
            legendHoverLink: false, // 移入leged不显示在中间
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                position: 'center',
                formatter: emphasisFormat.join('\n'),
                rich: {
                  a: {
                    fontSize: 18,
                    lineHeight: 32
                  },
                  b: {
                    color: 'rgba(255,255,255,.8)',
                    fontSize: 14,
                    lineHeight: 18
                  }
                }
              }
            },
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      })
      chart.dispatchAction({
        type: 'highlight',
        name: maxItem.name
      })
      let currentName = maxItem.name
      const validNumList = [] // 非零的项集合
      for (let i = 0; i < seriesData.length; i++) {
        const item = seriesData[i]
        if (item.value) {
          validNumList.push(item.name)
        }
      }
      let selectedList = []
      chart.on('legendselectchanged', params => {
        selectedList = []
        chart.dispatchAction({
          type: 'downplay',
          name: currentName
        })
        for (const key in params.selected) {
          if (params.selected[key]) {
            selectedList.push(key)
          }
        }
        if (selectedList.length) {
          if (validNumList.length) {
            const validSelectedList = [] // legend中选中的非零的项集合
            for (let i = 0; i < validNumList.length; i++) {
              const item = validNumList[i]
              if (selectedList.includes(item)) {
                validSelectedList.push(item)
              }
            }
            if (validSelectedList.length) {
              currentName = validSelectedList[0]
            } else {
              currentName = selectedList[0]
            }
          } else {
            currentName = selectedList[0]
          }
          chart.dispatchAction({
            type: 'highlight',
            name: currentName
          })
        }
      })
      chart.on('mouseover', params => {
        chart.dispatchAction({
          type: 'downplay',
          name: currentName
        })
        currentName = params.name
        chart.dispatchAction({
          type: 'highlight',
          name: params.name
        })
      })
      // 鼠标移出
      chart.on('mouseout', params => {
        chart.dispatchAction({ // 持续高亮
          type: 'highlight',
          name: currentName
        })
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.leasingOverview
  width 100%
  .w-title-border-left
    margin 24px 0 0 24px
  .pie-box
    width 100%
    height 240px
  .brand-decision
    position relative
    top 5px
  .date-picker-sty
    margin-right 24px
  .store-decision
    height 330px
  .common-shadow-panel
    padding-bottom 5px
    position relative
    overflow hidden
    .down-panel
      position absolute
      bottom 5px
      cursor pointer
      width 97%
      text-align center
      background #272930
      &:hover
        .icon-show-more:before
          color #f89407
    .mt-40
      margin-top 40px
    .common-data-list
      &>li
        margin 28px 0 0 0
        width 33.2%
        overflow hidden
        display inline-block
        text-align center
        color #808191
        border-right 1px solid #373A43
        height 128px
        &:last-child
          border none
        .number-p
          color #fff
          margin 16px 0 9px 0
          .number-span
            font-size 30px
        p
          font-size 14px
        p.list
          text-align left
          margin 10px auto
          width 350px
          overflow hidden
          display table
          white-space nowrap
          span
            display table-cell
            vertical-align middle
          span.name
            display inline-block
            margin-right 28px
            color #808191
            text-align right
            font-size 13px
            height 38px
          span.data
            color #fff
            display inline-block
            text-align left
            height 38px
            line-height 38px
          span.trend
            height 38px
            line-height 38px
            display inline-block
            font-size 12px
            margin-left 10px
            i.c-icon-up
              border-left 4px solid transparent
              border-right 4px solid transparent
              border-bottom 6px solid #FF754C
            i.c-icon-down
              border-left 4px solid transparent
              border-right 4px solid transparent
              border-top 6px solid #89DF81
          span.trend-up
            color #FF754C
          span.trend-down
            color #89DF81
    .w-singed-table
      overflow-y scroll
      height 143px
      box-sizing border-box
      padding-bottom 24px
      li
        margin 7px 0 0 0
        height auto
        vertical-align top
    .box-1
      overflow hidden
      margin-bottom 56px
      .side-box
        width 360px
      .rent-plan-tbs
        width auto
        height 420px
        overflow-y auto
        border 1px solid #373A43
        border-radius 2px
      .landing-tbs
        height 316px
        overflow-y auto
        border 1px solid #373A43
        border-radius 2px
      .main-box
        margin 32px 24px 0 388px
    .box-key
      width auto
      height 434px
      background #272930
      border 1px solid #383A41
      box-shadow 0px 0px 30px rgba(0, 0, 0, 0.3)
      border-radius 2px
      margin 0 16px 60px 16px
      overflow hidden
      .side-box
        width 380px
      .main-box
        margin 32px 24px 0 368px
.leasingOverview>>>
  .tbs-black
    margin-right 12px
    td
      color #808191 !important
</style>
